@import '../../../assets/style/variables.module.scss';

.payment {
  padding-top: 25px;
  border-right: 1px solid #e6e6e6;
  min-height: 85vh;
  padding-right: 60px;
  box-sizing: border-box;

  .content {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    gap: 25px;

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 25px;

      .inputWrapper {
        display: flex;
        gap: 35px;
      }

      label {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h5 {
          font-size: 12px;
          font-family: $--SF-UI-Display;
          font-weight: 400;
        }

        input {
          width: 100%;
          border: 1px solid #53545d;
          height: 40px;
          box-sizing: border-box;
          padding-left: 15px;
          font-size: 12px;
        }
      }
    }

    .creditCard {
    }
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      border: 1px solid #cecece;
      padding: 13px 70px;
      font-size: 12px;
      font-family: $--SF-UI-Display;
      color: #53545d;
      letter-spacing: 0.05em;
    }

    .active {
      background: #53545d;
      color: white;
    }
  }
}

.masterpass {
  background: #fefbf1;
  border: 1px solid #b5a176;
  margin-top: 40px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  img {
    height: 20px;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .info {
    h5 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    button {
      background: #b5a176;
      color: white;
      padding: 12px 25px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .payment {
    width: 90%;
    padding: 0;
    margin-top: 30px;
    border: 0;

    .buttonWrapper {
      button {
        padding: 10px 8px;
      }
    }

    .content {
      flex-direction: column;
    }
  }

  .masterpass {
    .info {
      h5 {
        font-size: 14px;
        width: 220px;
      }
    }
  }
}
