@import '../../assets/style/variables.module.scss';

.productVideo {
  width: 100%;
  max-width: $--max-width-desktop;
  margin-top: 100px;
  position: relative;
  cursor: pointer;

  .cover {
    width: 100%;
  }

  .play {
    position: absolute;
    bottom: 30px;
    left: 45px;
    height: 52px;

    img {
      height: 100%;
    }
  }

  video {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 940px) {
  .productVideo {
    width: 100%;
    .cover {
      height: 450px;
      object-fit: cover;
      object-position: -70px;
    }

    .play {
      height: 40px;
      left: 30px;
      bottom: 25px;
    }
  }
}
