@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  max-width: $--max-width-desktop;
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 60px;

    .mapWrapper {
      width: 100%;
      height: 650px;
      margin-bottom: 100px;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .listWrapper {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      min-width: 40%;

      h1 {
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 300;
        line-height: 35px;
        color: #53545d;
      }

      p {
        font-size: 14px;
        color: #53545d;
        font-weight: 300;
        line-height: 20px;
        font-family: $--SF-UI-Display;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        letter-spacing: 0.05em;
      }

      a {
        font-size: 14px;
        font-family: $--SF-UI-Display;
        font-weight: 300;
        color: #53545d;
        letter-spacing: 0.05em;
        margin-bottom: 5px;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    width: 95%;
    margin-top: 20px;
    .top {
      flex-direction: column;
      gap: 0px;

      .mapWrapper {
        height: 350px;

        img {
          height: 100%;
        }
        margin-bottom: 0;
      }

      .listWrapper {
        min-width: unset;
      }
    }
  }
}
