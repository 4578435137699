@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz@6..96&display=swap');

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Black.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Heavy.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Bold.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Thin.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Ultralight.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Light.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Medium.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Semibold.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Regular.woff2') format('woff2'),
    url('../fonts/SFUIDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Qara';
  src: url('../fonts/Qara-Regular.woff2') format('woff2'),
    url('../fonts/Qara-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$--max-width-desktop: 1320px;
$--max-width-laptop: 1150px;
$--SF-UI-Display: 'SF UI Display', sans-serif;
$--SF-Pro-Display: 'SF Pro Display', sans-serif;
$--Work-Sans: 'Work Sans', sans-serif;
$--Qara: 'Qara', sans-serif;

body,
button,
a,
select,
input {
  font-family: 'SF UI Display', sans-serif;
}

button {
  background: transparent;
  cursor: pointer;
}
