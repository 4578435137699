@import '../../assets/style/variables.module.scss';

.sideBar {
  width: 30%;

  .title {
    background: #f3f3f3;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 22px;

    h5 {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.05em;
      color: #53545d;
      font-family: $--SF-UI-Display;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    box-sizing: border-box;
    li {
      padding-left: 22px;

      box-sizing: border-box;
      position: relative;
    }
    a,
    button {
      background: transparent;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.05em;
      color: #53545d;
      font-family: $--SF-UI-Display;
    }
  }

  .active {
    &::before {
      content: '';
      width: 14px;
      height: 14px;
      background-color: #00ff6c;
      position: absolute;
      left: 0;
      border-radius: 50%;
    }
  }
}

@media only screen and (max-width: 940px) {
  .sideBar {
    display: none;
  }
}
