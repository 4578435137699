@import '../../../assets/style/variables.module.scss';

.products {
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    font-family: $--SF-UI-Display;
    font-size: 28px;
    color: #53545d;
    font-weight: 300;
    margin-top: 45px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 15px;
    letter-spacing: 0.05em;
  }

  .productWrapper {
    flex-direction: column;
    display: flex;
    width: 100%;
    border-right: 1px solid #f2f2f2;

    .productCard {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 70px;
      padding: 42px 60px;
      box-sizing: border-box;
      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }

      .mobileButtonWrapper {
        display: none;
      }

      .info {
        font-family: $--SF-UI-Display;
        color: #53545d !important;
        letter-spacing: 0.05em;
        position: relative;

        .delete {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
          font-weight: 500;
          text-decoration: underline;
          color: #53545f;
        }

        h3 {
          font-size: 16px;
          font-weight: 600;
        }
        h4 {
          font-size: 16px;
          font-weight: 400;
          margin-top: 15px;
        }
        h5 {
          font-size: 12px;
          font-weight: 500;
          margin-top: 15px;
        }

        .buttonWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 45px;

          .quantity {
            display: flex;
            width: 120px;
            height: 40px;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #53545d;
            padding: 0 13px;
            box-sizing: border-box;
            font-size: 12px !important;
            font-weight: 500 !important;
          }
          .gift {
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #53545d;
            padding: 0 13px;
            box-sizing: border-box;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.05em;
          }
        }
      }

      .mainImage {
        height: 195px;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .products {
    width: 90%;
    h1 {
      font-size: 23px;
      border: unset;
    }

    .productWrapper {
      border-right: unset;

      .productCard {
        padding: 20px 30px;
        padding-bottom: 60px;
        position: relative;

        .mobileButtonWrapper {
          position: absolute;
          bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 85%;

          button {
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            color: #53545f;
          }
        }

        .mainImage {
          height: 150px;
        }

        .info {
          .delete {
            display: none;
          }
          h3,
          h4 {
            font-size: 15px;
          }

          h5 {
            letter-spacing: 0.05em;
            line-height: 18px;
          }
          .buttonWrapper {
            margin-top: 20px;
            .gift {
              display: none;
            }
            .quantity {
              border: unset;
            }
          }
        }
      }
    }
  }
}
