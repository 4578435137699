@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  max-width: 1100px;
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  color: #53545e;

  h1 {
    font-size: 32px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  h2 {
    font-size: 12px;
    font-family: $--SF-UI-Display;
    font-weight: 400;
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    width: 95%;
  }
}
