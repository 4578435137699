@import '../../assets/style/variables.module.scss';

.productService {
  width: 100%;
  max-width: $--max-width-desktop;
  display: flex;
  background: #fafafa;
  margin-top: 100px;
  padding: 48px 52px 75px 54px;
  box-sizing: border-box;
  min-height: 530px;

  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    h2 {
      font-size: 28px;
      color: #53545d;
      line-height: 40px;
      font-family: $--SF-UI-Display;
      font-weight: 300;
    }
  }

  .right {
    ul {
      display: flex;
      flex-direction: column;
      gap: 24px;

      li {
        display: flex;

        svg {
          display: none;
        }

        h5 {
          min-width: 140px;
          font-size: 12px;
          color: #53545d;
          font-family: $--SF-UI-Display;
          font-weight: 400;
          margin-top: 5px;
        }

        p {
          min-width: 140px;
          font-size: 14px;
          line-height: 24px;
          color: #53545d;
          font-family: $--SF-UI-Display;
          font-weight: 300;
          max-width: 400px;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .productService {
    flex-direction: column;
    padding: 10% 0;
    align-items: center;
    position: relative;
    margin-top: 0px;

    .left {
      width: 90%;
      h2 {
        font-size: 26px;
        width: unset;
        max-width: unset;
      }

      svg {
        position: absolute;
        right: 0;
        bottom: -60px;
        height: 80px;
      }
    }

    .right {
      width: 90%;
      ul {
        gap: 0;
        li {
          border-bottom: 1px solid #53545f;
          padding: 30px 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          position: relative;
          transition: all 0.5s ease;
          svg {
            display: inline-block;
            position: absolute;
            right: 0;
            transition: all 0.5s ease;
          }
          h5 {
            font-size: 14px;
            font-weight: 400;
            padding-left: 10px;
          }
          p {
            padding-left: 10px;
            width: 90%;
            margin-top: 10px;
            opacity: 0;
            transition: all 0.3s ease;
            height: 0;
          }
        }

        .active {
          svg {
            transform: rotate(90deg);
          }
          p {
            opacity: 1;
            height: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1275px) {
  .productService {
    max-width: $--max-width-laptop;
  }
}
