@import '../../../assets/style/variables.module.scss';

.productCard {
  width: 100vw;
  background: #f3f3f3;
  padding: 80px 0;
  padding-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    right: 20vh;
  }

  .info {
    font-family: 'Bodoni Moda', serif;
    position: absolute;
    left: 10vh;
    h3 {
      font-size: 60px;
      color: #9ba4b5;
    }

    h4 {
      font-size: 40px;
      color: #9ba4b5;
      margin-top: 45px;
      font-family: 'Bodoni Moda';
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      font-size: 28px;
      font-family: $--SF-UI-Display;
      font-weight: 300;
      color: #303641;
    }

    p {
      margin-top: 23px;
      font-size: 12px;
      letter-spacing: 0.05em;
      font-family: $--SF-UI-Display;
      text-transform: uppercase;
    }

    button {
      font-size: 12px;
      padding: 13px 0;
      width: 150px;
      border: 1px solid #303641;
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .productCard {
    flex-direction: column;

    .info {
      position: static;

      h3 {
        font-size: 38px;
      }

      h4 {
        font-size: 32px;
      }
    }

    svg {
      position: static;
      margin-top: 50px;
    }
    .card {
      p {
        &:nth-child(5) {
          text-align: center;
          max-width: 70%;
          line-height: 23px;
        }
      }
    }
  }
}
