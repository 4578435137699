@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  display: flex;
  max-width: $--max-width-desktop;
  justify-content: space-between;

  .content {
    width: 70%;

    .emptyCart {
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h3 {
        font-weight: 400;
      }
    }
  }

  .summary {
    width: 30%;
  }
}

@media only screen and (max-width: 1275px) {
  .wrapper {
    max-width: $--max-width-laptop;
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    flex-direction: column;

    .summary,
    .content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
