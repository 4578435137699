@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  max-width: $--max-width-desktop;
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  box-sizing: border-box;

  .right {
    width: 80%;
  }

  .top {
    width: 100%;
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    color: #53545d;

    h1,
    h3 {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.05em;
      color: #53545d;
      font-family: $--SF-UI-Display;
    }
  }

  .cardWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h6 {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.05em;
      color: #53545d;
      font-family: $--SF-UI-Display;
      padding-top: 25px;
      padding-left: 22px;
    }

    .card {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 10px 50px;
      box-sizing: border-box;
      border: 1px solid #e6e6e6;
      align-items: center;
      color: #53545f;

      h3 {
        font-size: 12px;
        font-weight: 400;
      }

      h5 {
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
      }

      .left {
        display: flex;
        align-items: center;

        gap: 55px;
        img {
          height: 60px;
        }
      }

      .right {
        display: flex;
        gap: 100px;

        div:last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          h5 {
            color: #25c309;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    width: 95%;

    .right {
      width: 100%;

      .top {
        padding: 0;
        select {
          background: transparent;
          width: 100%;
          height: 100%;
          padding: 20px;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.05em;
          color: #53545d;
          font-family: $--SF-UI-Display;
        }
      }
    }

    .cardWrapper {
      .card {
        padding: 25px 12px 25px 30px;
        img {
          height: 70px;
        }
        .left {
          flex-direction: column;
          gap: 30px;
          align-items: flex-start;
        }

        .right {
          flex-direction: column;
          gap: 30px;

          div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }
      }
    }
  }
}
