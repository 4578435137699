@import '../../../assets/style/variables.module.scss';

.textPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  margin-top: 150px;
  margin-bottom: 150px;
  h3 {
    color: #475164;
    font-size: 90px;
    font-family: 'Bodoni Moda', serif;
    font-weight: 300;
    text-align: center;
    line-height: 60px;
    transition: all 0.5s ease;
  }

  p {
    font-family: 'Bodoni Moda', serif;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.05em;
    max-width: 80vw;
    min-width: 60vw;
    margin-top: 60px;
    line-height: 35px;
    color: #555c69;
  }
}

@media only screen and (max-width: 940px) {
  .textPart {
    height: 100%;
    padding: 70px 0;
    margin-bottom: -1px;
    margin-top: 0;

    h3 {
      font-size: 70px;
      line-height: 50px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
