@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  max-width: $--max-width-desktop;
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 60px;

    .mapWrapper {
      width: 100%;
      height: 650px;
    }

    .listWrapper {
      display: flex;
      flex-direction: column;
      padding-top: 50px;
      min-width: 500px;

      p {
        font-size: 12px;
        color: #53545d;
        font-weight: 300;
        line-height: 20px;
        display: flex;
        flex-direction: column;
      }

      h1 {
        margin-bottom: 35px;
        font-size: 24px;
        font-weight: 300;
        line-height: 35px;
        color: #53545d;
      }

      .selectWrapper {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        border-bottom: 1px solid #f3f3f3;
        margin-bottom: 30px;
      }

      select {
        border: 1px solid #53545d;
        padding: 16px 0 12px 21px;
        font-size: 12px;
        color: #53545d;
        font-weight: 500;

        margin-bottom: 20px;
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      button {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        height: 40px;

        &:first-child {
          color: white;
          background: #53545d;
        }

        &:nth-child(2) {
          color: #53545d;
          border: 1px solid #53545d;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    width: 95%;
    .top {
      flex-direction: column;
      gap: 0px;

      .mapWrapper {
        height: 400px;
      }

      .listWrapper {
        min-width: unset;
      }
    }
  }
}
