@import '../../assets/style/variables.module.scss';

.address {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e6e6e6;
  min-height: 100vh;

  .billButton {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
    padding-left: 25px;

    .square {
      width: 20px;
      height: 20px;
      border: 1px solid #53545d;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      font-family: $--SF-UI-Display;
      color: #53545d;
      font-size: 12px;
      letter-spacing: 0.05em;
      font-weight: 500;
      text-align: left;
    }
  }

  .active {
    .square {
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background: #53545d;
        position: absolute;
      }
    }
  }

  .title {
    padding: 23px 0;

    border-bottom: 1px solid #e6e6e6;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border: 1px solid #cecece;
      padding: 13px 113px;
      font-size: 12px;
      font-family: $--SF-UI-Display;
      color: #53545d;
      letter-spacing: 0.05em;
    }
  }

  .addressWrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 14px;
      color: #53545d;
      font-weight: 400;
      font-family: $--SF-UI-Display;
      margin: 20px 0;
    }

    .addressCard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 25px;
      box-sizing: border-box;
      border-bottom: 1px solid #e6e6e6;

      .select {
        display: flex;
        align-items: center;
        gap: 20px;

        .square {
          width: 20px;
          height: 20px;
          border: 1px solid #53545d;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          font-family: $--SF-UI-Display;
          color: #53545d;
          font-size: 12px;
          letter-spacing: 0.05em;
          font-weight: 500;
          width: 100px;
          text-align: left;
        }
      }

      .edit {
        font-family: $--SF-UI-Display;
        color: #53545d;
        font-size: 12px;
        letter-spacing: 0.05em;
        font-weight: 500;
        text-decoration: underline;
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 300px;

        h3,
        h4,
        h5,
        h6 {
          font-family: $--SF-UI-Display;
          color: #53545d;
          font-size: 12px;
          letter-spacing: 0.05em;
          font-weight: 400;
        }
      }
    }
  }
}

.formWrapper {
  padding: 20px 20px;
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
      width: 100%;
      border: 1px solid #53545d;
      height: 40px;

      input,
      select,
      textarea {
        width: 100%;
        height: 100%;
        padding-left: 20px;
        box-sizing: border-box;
        font-family: $--SF-UI-Display;
        font-weight: 400;
        font-size: 12px;
      }

      textarea {
        resize: none;
        padding-top: 10px;
        padding-left: 20px;
        height: 100px;
      }
    }

    .textareaWrapper {
      height: 100%;
    }

    button {
      background: #53545e;
      color: white;
      height: 40px;
      font-family: $--SF-UI-Display;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .address {
    min-height: unset;
    border-right: unset;

    .addressWrapper {
      .addressCard {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
      }
    }

    .billButton {
      padding: 0;
    }
  }

  .formWrapper {
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
}
