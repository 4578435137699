@import '../../../assets/style/variables.module.scss';
.video {
  width: 100%;
  height: 100%;
  position: relative;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.head {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 120px;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: top;
    transition: all 0.5s ease;
  }

  .info {
    position: absolute;
    left: 100px;
    z-index: 1;
    font-family: 'Bodoni Moda', serif;

    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: flex-start;

    h1 {
      font-size: 36px;
      color: white;
      font-weight: 300;
      line-height: 40px;
    }

    a {
      color: white;
      padding: 19px 56px;
      border: 1px solid white;
      margin-top: 72px;
      border-radius: 36px;
      font-family: $--SF-UI-Display;
    }
  }
}

@media only screen and (max-width: 940px) {
  .head {
    width: 100%;
    height: 870px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .scroll {
      position: absolute;
      bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      color: white;
      font-family: $--SF-UI-Display;
      font-size: 10px;
      gap: 5px;
    }

    .info {
      left: 22px;
      bottom: 120px;
      flex-direction: column;
      gap: 0px;

      a {
        margin-top: 70px;
      }

      h1 {
        font-size: 42px;
        color: white;
      }
    }
  }
}
