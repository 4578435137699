@import '../../../assets/style/variables.module.scss';

.crystal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;

  .diamondWrapper {
    position: absolute;
    top: 100px;
    z-index: 5;
    height: 100%;
    width: 90%;

    .diamond1 {
      position: absolute;
      bottom: 140vh;
      left: 0;
    }
    .diamond2 {
      position: absolute;
      bottom: 50vw;
      left: 400px;
    }
    .diamond3 {
      position: absolute;
      bottom: 120vh;
      left: 50vw;
    }
    .diamond4 {
      position: absolute;
      top: 0;
      left: 50vw;
    }

    .diamond5 {
      position: absolute;
      top: 0;
      right: 10vw;
    }

    .diamond6 {
      position: absolute;
      bottom: 120vh;
      left: 5vw;
    }
  }

  .head {
    width: 100%;
    height: 759px;
    position: relative;

    .headPart {
      position: absolute;
      bottom: -1px;
      z-index: 1;
      width: 100%;
      clip-path: polygon(0 60%, 100% 92%, 100% 100%, 0 100%);
      background: white;
      height: 500px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
      position: absolute;
    }
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 70px;
    box-sizing: border-box;
    margin-top: 50px;
    position: relative;
  }

  .center {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 70px;
    box-sizing: border-box;
    margin: 70px 0;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-size: 5.5vh;
        color: #2f3540;
        font-weight: 300;
        font-family: 'Bodoni Moda', serif;
        text-transform: uppercase;
        white-space: nowrap;
        margin-right: 20px;
        text-align: right;

        span {
        }
      }
    }
  }

  .topText {
    width: 100%;
    font-family: 'Bodoni Moda', serif;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 72px;
      color: #2f3540;
      font-weight: 300;

      span {
        margin-right: 40px;
      }
    }

    h4 {
      font-size: 42px;
      font-weight: 300;
    }
  }
  .tripleWrapper {
    display: flex;
    gap: 30px;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 940px) {
  .crystal {
    .diamondWrapper {
      position: absolute;
      top: 100px;
      z-index: 5;
      height: 20%;
      width: 90%;

      .diamond1 {
        position: absolute;
        top: 120px;
        left: 230px;
        height: 20px;
      }
      .diamond2 {
        position: absolute;
        top: -50px;
        left: 200px;
        height: 20px;
      }
      .diamond3 {
        position: absolute;
        top: -100px;
        left: -130px;
        height: 20px;
      }
      .diamond4 {
        position: absolute;
        top: -60px;
        left: -50px;
        height: 20px;
      }

      .diamond5 {
        position: absolute;
        top: 140px;
        left: 0;
        height: 20px;
      }

      .diamond6 {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .diamond7 {
        position: absolute;
        top: 20px;
        right: 270px;
        height: 20px;
        z-index: 1;
      }
    }

    .head {
      height: 240px;
      img {
        width: 100%;
      }
    }

    .wrapper {
      padding: 0;
      width: 90%;
      .topText {
        flex-direction: column-reverse;
        gap: 30px;
        h3 {
          font-size: 47px;
        }

        h4 {
          font-size: 28px;
        }
      }

      .mobileCenter {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 30px;
        img {
          &:nth-child(1) {
            margin-left: -20px;
          }
          &:nth-child(2) {
            margin-right: -20px;
          }
        }
      }

      .tripleWrapper {
        margin-top: 30px;
        flex-direction: column;
        margin-bottom: 50px;
        gap: 24px;

        .mobileImageWrapper {
          display: flex;
          gap: 24px;
        }
      }
    }
  }
}
