@import '../../assets/style/variables.module.scss';

.productImageGrid {
  width: 100%;
  max-width: $--max-width-desktop;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 80px;

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 940px) {
  .productImageGrid {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 0;

    div {
      display: grid;
      grid-template-columns: 1fr;
      gap: 5px;
    }
  }
}

@media only screen and (max-width: 1275px) {
  .productImageGrid {
    max-width: $--max-width-laptop;
  }
}
