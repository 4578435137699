@import '../../../assets/style/variables.module.scss';

.magazine {
  background: #0b0c0f;

  box-sizing: border-box;
  padding-bottom: 300px;
  .wrapper {
    display: flex;
    gap: 20px;
    position: relative;
  }

  .magazineCard {
    height: 475px;

    img {
      height: 100%;
    }
  }
}

@media only screen and (max-width: 940px) {
  .magazine {
    height: 100vh;
  }
}
