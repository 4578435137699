@import '../../assets/style/variables.module.scss';

.sliderWrapper {
  width: 612px;
  height: 560px;
  border: 1px solid rgb(233, 233, 233);
  position: relative;

  .dotWrapper {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    display: flex;
    gap: 14px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    .dot {
      width: 6px;
      height: 6px;
      border: 1px solid #53545e;
      border-radius: 50%;
      cursor: pointer;

      transition: all 0.5s ease;
    }

    .active {
      background: #53545e;
    }
  }

  .slider {
    & > div > div {
      display: flex;
    }
  }

  .contentCard {
    height: 560px;

    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 940px) {
  .sliderWrapper {
    width: 90%;
    height: 350px;

    .contentCard {
      height: 350px;
    }
  }
}
