@import '../../assets/style/variables.module.scss';

.signature {
  width: 100%;
  max-width: $--max-width-desktop;
  margin-top: 100px;
  display: flex;
  align-items: center;
  gap: 100px;
  justify-content: center;

  .text {
    p {
      color: #53545d;
      font-size: 28px;
      font-weight: 200;
      line-height: 48px;
      font-family: $--SF-UI-Display;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 60px;
      h4 {
        color: #53545d;
        font-size: 28px;
        font-weight: 200;
        line-height: 48px;
        font-family: $--SF-UI-Display;
        text-decoration: underline;
        margin-top: 20px;
      }
      .signatureIcon {
        height: 68px;
      }
    }
  }

  .imageWrapper {
    position: relative;

    &::before {
      content: '';
      width: 190px;
      height: 190px;
      position: absolute;
      bottom: -20px;
      left: -20px;
      background: #f5e5e1;
    }

    .image {
      position: relative;
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 940px) {
  .signature {
    margin-top: 70px;
    width: 100%;
    flex-direction: column-reverse;
    gap: 57px;

    .imageWrapper {
      width: 90%;
    }

    .text {
      width: 85%;
      p {
        font-size: 22px;
        line-height: 30px;
      }

      .bottom {
        margin-top: 25px;
        h4 {
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}
