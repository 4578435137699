@import '../../assets/style/variables.module.scss';

.collectionGrid {
  width: 100%;
  max-width: $--max-width-desktop;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  h2 {
    color: #53545d;
    font-size: 28px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
  }

  .discover {
    color: #53545d;
    font-size: 12px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 280px;
    margin-top: 100px;
  }
}

.collectionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 28px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
    color: #53545d;
    margin-top: 27px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
    color: #53545d;
    margin-top: 12px;
  }

  h4 {
    font-family: $--SF-Pro-Display;
    color: #53545d;
    font-weight: 300;
    margin-top: 8px;
    font-size: 14px;

    span {
      font-weight: 700;
      font-family: $--SF-UI-Display;
    }
  }

  img {
    height: 413px;
  }
}

@media only screen and (max-width: 940px) {
  .collectionGrid {
    .grid {
      overflow-y: hidden;
      gap: 80px;
      padding-right: 50px;
      padding-left: 50px;

      box-sizing: border-box;
      overflow-x: scroll;
      padding-bottom: 35px;

      &::-webkit-scrollbar {
        height: 1px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f4f4f4;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #53545d;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #53545d;
      }
    }
  }

  .collectionCard {
    min-width: 130px;
    img {
      width: 100%;
      height: unset;
    }

    h3 {
      font-size: 21px;
    }
  }
}

@media only screen and (max-width: 1275px) {
  .collectionGrid {
    max-width: $--max-width-laptop;
  }
}
