@import '../../assets/style/variables.module.scss';

.bottomInfo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  max-width: $--max-width-desktop;
  margin: auto;
  margin-top: 100px;
}

.infoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  h5 {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: #53545d;
    font-family: $--SF-UI-Display !important;
    font-weight: 300;

    max-width: 245px;
  }
}

@media only screen and (max-width: 940px) {
  .bottomInfo {
    grid-template-columns: 1fr;
    margin-top: 30px;
    width: 90%;
  }
  .infoCard {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    border-bottom: 1px solid #53545f;
    gap: 30px;

    h5 {
      text-align: left;
      max-width: unset;
    }

    svg {
      height: 40px;
    }
  }
}

@media only screen and (max-width: 1275px) {
  .bottomInfo {
    max-width: $--max-width-laptop;
  }
}
