@import '../../../assets/style/variables.module.scss';

.videoCard {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  .video {
    width: 100%;
    height: 100%;
    position: relative;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .play {
      position: absolute;
      bottom: 30px;
      left: 45px;
      height: 52px;

      img {
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .videoCard {
    height: 720px;
    position: relative;
    background: white;

    position: relative;
    z-index: 2;

    .video {
      img {
        object-position: -100px;
      }
    }
  }
}
