@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  .top {
    width: 100%;
    max-width: $--max-width-desktop;
    display: flex;
    gap: 125px;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 105px;
    max-width: $--max-width-desktop;
    margin-top: 100px;
  }

  .signatureWrapper {
    width: 100%;
    max-width: $--max-width-desktop;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    .top {
      flex-direction: column;
      gap: 35px;
      align-items: center;
    }

    .row {
      grid-template-columns: 1fr;
      width: 100%;
      margin-top: 45px;
      gap: 70px;
    }
  }
}

@media only screen and (max-width: 1275px) {
  .wrapper {
    .top,
    .row {
      max-width: $--max-width-laptop;
    }
  }
}
