@import '../../assets/style/variables.module.scss';



.cross {
  position: relative;
  display: inline-block;
  color: #53545d;
}
.cross::before, .cross::after {
  content: '';
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.cross::before {
  border-bottom: 1px solid red;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
}

.striketextcolor{
  color: #53545d;

}
.productInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  position: relative;
  z-index: 1;
  h1 {
    font-size: 28px;
    color: #53545d;
    font-family: $--SF-UI-Display;
    font-weight: 300;
  }
  h2 {
    font-size: 14px;
    color: #53545d;
    font-family: $--SF-UI-Display;
    font-weight: 400;
    margin-top: 8px;
  }
  h3 {
    font-size: 14px;
    color: #53545d;
    font-family: $--SF-UI-Display;
    font-weight: 500;
    margin-top: 8px;
    letter-spacing: 0.05em;
  }

  .addToCart {
    background: #303641;
    width: 100%;
    height: 40px;
    font-family: $--SF-UI-Display;
    font-weight: 500;
    margin-top: 8px;
    letter-spacing: 0.05em;
    font-size: 14px;
    color: white;
    margin-top: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .hasCart {
    background: white;
    border: 1px solid #53545e;
    color: #53545e;
  }

  .info {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      font-size: 12px;
      color: #53545d;
      font-family: $--SF-UI-Display;
      font-weight: 500;
      margin-top: 8px;
      letter-spacing: 0.05em;
    }
    p {
      font-size: 14px;
      color: #818181;
      font-family: $--SF-UI-Display;
      font-weight: 300;
      margin-top: 8px;
      letter-spacing: 0.05em;
      text-align: center;
      line-height: 20px;
    }
  }

  .buttonWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    width: 100%;
    margin-top: 25px;

    button {
      border: 1px solid #d6d7d8;
      height: 40px;
      color: #53545f;
      font-family: $--SF-UI-Display;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.05em;
    }
  }
}

@media only screen and (max-width: 940px) {
  .productInfo {
    width: 90%;
    margin-top: 0;

    h1 {
      width: 100%;
    }
    h2 {
      width: 100%;
    }
    h3 {
      width: 100%;
    }

    .addToCart {
      margin-top: 33px;
    }

    .info {
      margin-top: 35px;
    }
  }
}
