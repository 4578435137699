@import '../../assets/style/variables.module.scss';

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #53545e;

  img {
    margin-bottom: 1px;
    max-height: 336px;
  }

  .cross {
    position: relative;
    display: inline-block;
    color: #53545d;
  }
  .cross::before,
  .cross::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .cross::before {
    border-bottom: 1px solid red;
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
  }

  h4 {
    font-size: 28px;
    font-family: $--SF-UI-Display;
    font-weight: 300;
  }

  p {
    font-size: 12px;
    font-family: $--SF-UI-Display;
    font-weight: 400;
    margin-top: 8px;
  }

  h5 {
    margin-top: 23px;
    font-size: 14px;
    font-family: $--SF-UI-Display;
    font-weight: 400;
  }
}

@media only screen and (max-width: 940px) {
  .product {
    width: 100%;

    img {
      width: auto;
    }

    .home {
      width: auto;
    }
    h4 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
    }

    h5 {
      font-size: 14px;
      margin-top: 15px;
    }
  }
}
