@import '../../../assets/style/variables.module.scss';
.video {
  width: 100%;
  height: 100%;
  position: relative;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.head {
  width: 100vw;
  height: 100vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: top;
    transition: all 0.5s ease;
  }

  .info {
    position: absolute;
    left: 100px;
    bottom: 200px;
    z-index: 1;
    font-family: 'Bodoni Moda', serif;
    transition: all 0.5s ease;
    display: flex;
    justify-content: space-between;
    width: 90%;

    h1 {
      font-size: 72px;
      color: #0089ff;
      font-weight: 300;
      text-transform: uppercase;

      span {
        margin-right: 40px;
      }
    }

    h2 {
      font-size: 44px;
      color: white;
      font-weight: 400;
      margin-left: 20px;
      margin-top: 10px;
      text-transform: uppercase;

      span {
        margin-right: 130px;
      }
    }

    h4 {
      font-size: 70px;
      color: white;
      letter-spacing: -0.05em;
      margin-bottom: 20px;
      font-weight: 200;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        margin-right: 90px;
        font-size: 50px;
        letter-spacing: 0.05em;
      }
    }

    h3 {
      font-size: 44px;
      color: #000000;
      text-align: center;
    }

    .second {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h3 {
        font-family: 'Bodoni Moda', serif;
        font-weight: 300;
      }

      h4 {
        font-family: 'Bodoni Moda', serif;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .head {
    width: 100%;
    height: 870px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .scroll {
      position: absolute;
      bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      color: white;
      font-family: $--SF-UI-Display;
      font-size: 10px;
      gap: 5px;
    }

    .info {
      left: 22px;
      bottom: 120px;
      flex-direction: column;
      gap: 150px;

      h1 {
        font-size: 52px;
        color: white;
      }

      h2 {
        font-size: 32px;

        span {
          margin-right: 90px;
        }
      }

      h3 {
        font-size: 28px;
        color: white;
        width: 100%;
        white-space: nowrap;
      }
      h4 {
        font-size: 45px;
        width: 100%;
        align-items: flex-start;
        margin-top: 10px;
        span {
          font-size: 39px;
          text-align: right;
          width: 100%;
        }
      }
    }
  }
}
