@import '../../assets/style/variables.module.scss';

.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 65px;

  h2 {
    color: #53545d;
    font-size: 28px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
  }

  h3 {
    color: #53545d;
    font-size: 12px;
    font-weight: 400;
    font-family: $--SF-UI-Display;
    margin-top: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  h5 {
    font-size: 14px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
    margin-top: 20px;
    color: #03672d;
  }

  label {
    display: flex;
    margin-top: 25px;
    height: 40px;

    input {
      border: 1px solid #53545d;
      height: 100%;
      padding-left: 19px;
      width: 330px;
      box-sizing: border-box;
      padding-bottom: 3px;
      font-size: 14px;
      border-radius: 0px;

      &::placeholder {
        font-size: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #53545d;
      }
    }

    button {
      width: 45px;
      height: 100%;
      border: 1px solid #53545d;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -1px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .newsletter {
    width: 90%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 65px;

    h2,
    h3 {
      width: 100%;
    }

    label {
      width: 100%;
    }
  }
}
