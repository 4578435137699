@import '../../assets/style/variables.module.scss';

.oldfactory {
  h2 {
    color: #53545d;
    font-size: 28px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
  }
  .content {
    position: relative;
    margin-top: -45px;
    .bottle {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .icon1,
      .icon2,
      .icon3,
      .icon4 {
        position: absolute;
      }

      .icon1 {
        top: 115px;
      }
      .icon2 {
        top: 190px;
      }
      .icon3 {
        bottom: 11px;
      }
      .icon4 {
        bottom: 60px;
      }
    }

    .info1,
    .info2,
    .info3 {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-between;
      top: 92px;
      border-top: 1px solid #53545f;
      padding-top: 14px;

      h4,
      h5 {
        color: #53545f;
        font-size: 12px;
        font-weight: 400;
        font-family: $--SF-UI-Display;
        line-height: 18px;
        letter-spacing: 0.05em;
      }

      h5 {
        text-align: right;
      }
    }

    .info2 {
      top: 180px;
    }

    .info3 {
      top: unset;
      bottom: 25px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .oldfactory {
    align-items: flex-start;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;

    .content {
      align-items: flex-end;
      display: flex;
      width: 100%;

      .info1,
      .info2,
      .info3 {
        flex-direction: column;
        justify-content: flex-start;
        //gap: 14px;

        h4 {
          opacity: 0.33;
        }
        h5 {
          text-align: left;
        }
      }

      .info3 {
        bottom: -4px;
      }

      .bottle {
        position: relative;
        width: 100%;
        align-items: flex-end;
        left: 27vw;
      }
    }
  }
}
