@import '../../assets/style/variables.module.scss';

.home {
  overflow-x: hidden;
  background: #0b0c0f;
}

.buy {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 100;
  background: #65eeeb;
  padding: 14px 45px;
  color: #012e2d;
  font-family: 'SF UI Display', sans-serif;
  border-radius: 100px;
  font-size: 14px;
}

.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.halfTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 300px;
  margin-top: 100px;
  h4 {
    color: #9ba4b5;
    font-size: 56px;
    font-family: 'Bodoni Moda', serif;
    text-align: center;
    font-weight: 200;
  }
}

.triple {
  width: 100%;
  display: flex;
  gap: 30px;
  margin: auto;
  padding: 0 70px;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.tripleWrapper {
  display: flex;
  gap: 30px;
  width: 100%;
  align-items: center;

  img {
    width: 90%;

    &:first-child {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 940px) {
  .buy {
    right: 20px;
    bottom: unset;
    bottom: 60px;
  }

  .triple {
    flex-direction: column;
    padding: 0;
    width: 90%;
    margin-bottom: 30px;
  }

  .halfTitle {
    h4 {
      font-size: 35px;
    }
  }

  .tripleWrapper {
    margin-top: 30px;
    flex-direction: column;
    margin-bottom: 50px;
    gap: 24px;

    .mobileImageWrapper {
      display: flex;
      gap: 24px;
    }
  }
}
