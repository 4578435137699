@import '../../../assets/style/variables.module.scss';

.summary {
  width: 100%;

  .product {
    display: flex;
    padding: 30px 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;

    img {
      height: 150px;
      width: 170px;
      object-fit: contain;
    }

    .productInfo {
      color: #53545d;
      letter-spacing: 0.05em;
      display: flex;
      flex-direction: column;
      gap: 10px;

      h3,
      h4,
      h5 {
        font-size: 16px;
        font-weight: 500 !important;
        font-family: $--SF-UI-Display !important;
      }
    }
  }

  .title {
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    padding-left: 34px;
    padding-bottom: 15px;
    letter-spacing: 0.05em;
    box-sizing: border-box;

    h2 {
      font-family: $--SF-UI-Display;
      font-size: 28px;
      color: #53545d;
      font-weight: 300;
    }

    button {
      font-size: 12px;
      font-family: $--SF-UI-Display;
      color: #53545d;
      text-decoration: underline;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 34px;
    padding-top: 25px;
    box-sizing: border-box;
    gap: 20px;
    letter-spacing: 0.05em;

    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: #53545d;
        font-family: $--SF-UI-Display;
        font-weight: 400;
      }

      h5 {
        font-size: 16px;
        color: #53545d;
        font-family: $--SF-UI-Display;
        font-weight: 600;
      }

      &:last-child {
        margin-top: 28px;
      }
    }
  }

  .buttonWrapper {
    padding-left: 34px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 27px;
    margin-top: 60px;

    button {
      background: #53545e;
      color: white;
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      font-family: $--SF-UI-Display;
      letter-spacing: 0.05em;

      &:last-child {
        background: white;
        color: #53545e;
        border: 1px solid #53545e;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .summary {
    width: 90%;
    .title {
      display: none;
    }

    .product {
      img {
        width: 120px;
      }
      .productInfo {
        width: 60%;
        h3,
        h4,
        h5 {
          font-size: 14px;
        }
      }
    }

    ul {
      border-top: 1px solid #e6e6e6;
      padding: 0;
      padding-top: 25px;
    }

    .buttonWrapper {
      padding: 0;
      margin-top: 30px;
      flex-direction: column-reverse;
      gap: 20px;

      button {
        &:last-child {
          border: unset;
          text-decoration: underline;
        }
      }
    }
  }
}
