@import '../../assets/style/variables.module.scss';

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124px;
  position: relative;
  z-index: 2;

  .topCover {
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0;
    left: 0;
    background: linear-gradient(
      #030303 0%,
      rgba(3, 3, 3, 0.99) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0.4;
    mix-blend-mode: color;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    max-width: $--max-width-desktop;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
    z-index: 1;

    .logo {
      margin-top: 25px;
      margin-left: 10px;
    }

    .left {
      min-width: 119px;
    }

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .right {
      .linkWrapper {
        margin-right: 20px;
      }
    }

    .linkWrapper {
      display: flex;
      gap: 40px;

      li {
        a {
          font-size: 12px;
          font-family: $--SF-Pro-Display;
          letter-spacing: 0.05em;
          font-weight: 500;
          color: #53545d;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .finder {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 65px;
      span {
        font-size: 12px;
        font-weight: 500;
        font-family: $--SF-Pro-Display;
        letter-spacing: 0.05em;
        color: #53545d;
      }
    }

    .buttonWrapper {
      display: flex;
      gap: 30px;
      align-items: center;

      .navigation {
        svg {
          width: 17px;
          g {
            g {
              path {
                fill: #53545d;
                stroke-width: 0;
              }
            }
          }
        }
      }

      .lang {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        select {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
        span {
          font-size: 12px;
          font-weight: 500;
          font-family: $--SF-Pro-Display;
          letter-spacing: 0.05em;
          color: #53545d;
        }
      }

      .cart {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
        .circle {
          position: absolute;
          background: #00ff6c;
          width: 10px;
          height: 10px;
          top: -1px;
          left: -5px;
          border-radius: 50%;
        }
        span {
          font-family: $--Work-Sans;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.05em;
          color: #53545d;
          position: absolute;
          margin-top: 5.5px;
        }
      }
    }
  }
}

.home {
  position: absolute;
  top: 0;
  z-index: 1;
  .wrapper {
    border-bottom: 0;
    max-width: unset;
    padding: 0 70px;

    .left {
    }
    .linkWrapper {
      li {
        a {
          color: white;
        }
      }
    }

    .right {
      .finder {
        span {
          color: white;
        }
      }

      .buttonWrapper {
        .navigation {
          svg {
            width: 17px;
            g {
              g {
                path {
                  fill: white;
                  stroke-width: 0;
                }
              }
            }
          }
        }

        .lang {
          span {
            color: white;
          }

          svg {
            path {
              stroke: white;
            }
          }
        }

        button {
          &:nth-child(2) {
            svg {
              path,
              g,
              circle,
              line {
                stroke: white !important;
              }
            }
          }

          &:nth-child(3) {
            svg {
              path,
              g,
              circle,
              line {
                stroke: white !important;
              }
            }
          }

          &:nth-child(4) {
            svg {
              path,
              g,
              circle,
              line {
                stroke: white !important;
              }
            }
          }
        }

        .cart {
          span {
            color: white;
          }

          svg {
            path,
            g,
            circle,
            line,
            rect {
              stroke: white;
            }
          }
        }
      }
    }
  }
}

.mobileMenu {
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  .logo {
    height: 45px;
    margin-left: 40px;

    img {
      height: 100%;
    }
  }
  .menuIcon {
  }

  .buttonWrapper {
    display: flex;
    gap: 25px;

    .cart {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;
      .circle {
        position: absolute;
        background: #00ff6c;
        width: 10px;
        height: 10px;
        top: 10px;
        left: -5px;
        border-radius: 50%;
      }
      span {
        font-family: $--Work-Sans;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: #53545d;
        position: absolute;
        margin-top: 5.5px;
      }
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: flex-start;

  .stickyHeader {
    height: 80px;
    width: 100%;
    background-color: #0b0c0f;
    display: flex;
    align-items: center;
    padding: 0 130px;
    box-sizing: border-box;
    justify-content: space-between;
    .buy {
      background: #65eeeb;
      padding: 14px 45px;
      color: #012e2d;
      font-family: 'SF UI Display', sans-serif;
      border-radius: 100px;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 1275px) {
  .header {
    .wrapper {
      max-width: $--max-width-laptop;
    }
  }
}

@media only screen and (max-width: 940px) {
  .mobileMenu {
    width: 100%;
    display: flex;
    padding: 0 5%;
    padding-top: 40px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;

    position: relative;

    .logo {
      margin-left: 0 !important;
    }

    .buttonWrapper {
      position: absolute;
      right: 20px;
    }

    .menuIcon {
      position: absolute;
      left: 20px;
    }
  }

  .homeMobile {
    position: absolute;
    top: 0;
    z-index: 5;

    .menuIcon {
      svg {
        g {
          line {
            stroke: white;
          }
        }
      }
    }

    .buttonWrapper {
      button {
        svg {
          path,
          g,
          circle,
          line {
            stroke: white;
          }
        }
      }
    }

    .buttonWrapper {
      button {
        // &:first-child {
        //   svg {
        //     path,
        //     circle {
        //       stroke: white !important;
        //     }
        //   }
        // }
      }

      .lang {
        span {
          color: white;
        }

        svg {
          path {
            stroke: white;
          }
        }
      }

      button {
        // &:nth-child(2) {
        //   svg {
        //     path,
        //     g,
        //     circle,
        //     line {
        //       stroke: white;
        //     }
        //   }
        // }

        // &:nth-child(3) {
        //   svg {
        //     path,
        //     g,
        //     circle,
        //     line {
        //       stroke: white;
        //       fill: white;
        //     }
        //   }
        // }

        // &:nth-child(4) {
        //   svg {
        //     path,
        //     g,
        //     circle,
        //     line {
        //       stroke: white;
        //     }
        //   }
        // }
      }

      .cart {
        span {
          color: white;
        }

        svg {
          path,
          g,
          circle,
          line,
          rect {
            stroke: white;
          }
        }
      }
    }
  }

  .stickyMobile {
    position: fixed;
    top: 0;
    background: #0b0c0f;
    padding-bottom: 20px;
  }

  .mobileBasket {
    border: 1px solid #81d8d0;
    padding: 17px 23px;
    box-sizing: border-box;
    border-radius: 35px;
    position: absolute;
    right: 20px;
  }

  .menu {
    position: absolute;
    top: 150px;
    left: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    li {
      a {
        color: white;
      }
    }

    .signOut {
      color: white;
      text-align: left;
    }

    .lang {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 50px;

      select {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        font-family: $--SF-Pro-Display;
        letter-spacing: 0.05em;
        color: white;
        margin-right: 10px;
      }
    }
  }

  .full {
    height: 100vh;
    align-items: flex-start;
    background: rgba($color: #0b0c0f, $alpha: 0.9);
    position: fixed;
    top: 0;
    z-index: 10000;
  }

  .close {
    position: absolute;
    right: 20px;
  }
}
