@import '../../assets/style/variables.module.scss';

.satisSozlesmesi {
  width: 100%;
  max-width: $--max-width-desktop;
  margin-top: 50px;

  table {
    border-collapse: collapse;
    width: 70%;
    margin: 40px 0;
    font-size: 14px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }

  h1 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
    width: 70%;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 25px;
    width: 70%;
  }
}

@media only screen and (max-width: 940px) {
  .satisSozlesmesi {
    width: 90%;

    table,
    h2,
    p,
    h1 {
      width: 100%;
    }
  }
}
