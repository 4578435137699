@import '../../../assets/style/variables.module.scss';

.tripleCard {
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-height: 100vh;

  .whitePart {
    position: absolute;
    z-index: 1;
    background: white;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  h3 {
    color: white;
    font-size: 55px;
    font-family: 'Bodoni Moda', serif;
    font-weight: 300;
    text-align: center;
    line-height: 35px;
    transition: all 0.5s ease;
    position: absolute;
    bottom: 230px;
  }

  .tripleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 520px;
      transition: all 0.5s ease;
      position: relative;
    }

    .center {
      object-fit: cover;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 940px) {
  .mobile {
    margin-bottom: -3px;
  }
}
