@import '../../assets/style/variables.module.scss';

.inspiration {
  h2 {
    color: #53545d;
    font-size: 28px;
    font-weight: 300;
    font-family: $--SF-UI-Display;
  }

  p {
    color: #818181;
    font-size: 14px;
    font-family: $--SF-UI-Display;
    font-weight: 300;
    margin-top: 40px;
    letter-spacing: 0.05em;
    line-height: 20px;
  }
}

@media only screen and (max-width: 940px) {
  .inspiration {
    width: 90%;
    margin: auto;
  }
}
