@import '../../assets/style/variables.module.scss';

.footer {
  width: 100%;
  max-width: $--max-width-desktop;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .top {
    border-top: 1px solid #dbdbdb;
    width: 100%;
    display: flex;
    padding: 30px 20px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: flex-start;

    .row {
      h5 {
        font-size: 22px;
        font-weight: 300;
        color: #53545d;
        font-family: $--SF-UI-Display;
        margin-bottom: 30px;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        li {
          a {
            color: #53545d;
            font-size: 12px;
            font-weight: 400;
            font-family: $--SF-UI-Display;
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dbdbdb;
    padding-top: 90px;
    max-height: 190px;
    overflow: hidden;
    padding-bottom: 50px;
    box-sizing: border-box;

    h6 {
      font-size: 12px;
      color: #53545d;
      font-family: $--SF-Pro-Display;
    }

    .lang {
      h5 {
        font-size: 12px;
        color: #53545d;
        font-family: $--SF-Pro-Display;
      }
    }

    .logo {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        position: absolute;
        top: -50px;
        margin-left: 70px;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .mobile {
    width: 90%;

    .bottom {
      flex-direction: column;
      gap: 80px;
      padding-top: 60px;
      max-height: 250px;
      .copyright {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .logo {
        margin-bottom: 100px;
      }
    }

    .buttonWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      .linkWrapper {
        width: 100%;

        button {
          svg {
            transform: rotate(-90deg);
            transition: all 0.3s ease;
          }
        }

        .activeButton {
          svg {
            transform: rotate(0);
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          margin-top: 20px;

          li {
            a {
              color: #53545d;
              font-size: 16px;
              font-weight: 400;
              font-family: $--SF-UI-Display;
            }
          }
        }
      }

      button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #53545f;
        padding: 24px 0;

        h5 {
          font-size: 22px;
          font-weight: 300;
          color: #53545d;
          font-family: $--SF-UI-Display;
        }
      }
    }
  }
}
@media only screen and (max-width: 1275px) {
  .footer {
    max-width: $--max-width-laptop;
  }
}
