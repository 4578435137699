@import '../../assets/style/variables.module.scss';

.topBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: $--max-width-desktop;
    height: 45px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 100%;
      li,
      span,
      a {
        color: #53545d;
        font-size: 12px;
        font-family: $--SF-UI-Display;
        font-weight: 500;
      }

      li:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .topBar {
    width: 90%;
  }
}

@media only screen and (max-width: 1275px) {
  .topBar {
    max-width: $--max-width-laptop;
  }
}
