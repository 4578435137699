@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  max-width: $--max-width-desktop;
  display: flex;
  gap: 60px;

  .left {
    height: 650px;
    height: 650px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .right {
    width: 50%;
    padding-top: 38px;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 24px;
      color: #53545d;
      font-family: $--SF-UI-Display;
      font-weight: 400;
      margin-bottom: 35px;
    }

    .register {
      color: #53545e;
      border: 1px solid #53545e;
      height: 40px;
      font-family: $--SF-UI-Display;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        width: 100%;
        border: 1px solid #53545d;
        height: 40px;

        input {
          width: 100%;
          height: 100%;
          padding-left: 20px;
          box-sizing: border-box;
          font-family: $--SF-UI-Display;
          font-weight: 400;
          font-size: 12px;
        }
      }

      button {
        background: #303641;
        color: white;
        height: 40px;
        font-family: $--SF-UI-Display;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .bottom {
      margin-top: 40px;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;
      padding: 27px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        font-family: $--SF-UI-Display;
        font-weight: 400;
        font-size: 12px;
        color: #53545e;
        line-height: 20px;
      }

      .remember {
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;

        h5 {
          font-family: $--SF-UI-Display;
          font-weight: 400;
          font-size: 12px;
          color: #53545e;
        }
        .square {
          width: 20px;
          height: 20px;
          border: 1px solid #53545d;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .active {
          &::before {
            content: '';
            width: 15px;
            height: 15px;
            background: #53545d;
            position: absolute;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1275px) {
  .wrapper {
    max-width: $--max-width-laptop;
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 10px;

    .left {
      height: 260px;
      width: 100%;
    }

    .right {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
}
