@import '../../assets/style/variables.module.scss';

.head {
  width: 100%;
  max-width: $--max-width-desktop;
  display: flex;
  flex-direction: column;
  gap: 90px;
  margin-bottom: 90px;
  img {
    width: 100%;
  }

  .title {
    color: #53545c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Bodoni Moda', serif;

    h2 {
      font-size: 44px;
      line-height: 50px;
    }
    p {
      font-size: 22px;
      line-height: 28px;
    }
  }
}

.productList {
  width: 100%;
  max-width: $--max-width-desktop;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0px;
  row-gap: 70px;
  box-sizing: border-box;

  .feed {
    width: 100%;
    color: #53545c;
    display: flex;
    flex-direction: column;

    h4 {
      font-family: $--SF-UI-Display;
      font-size: 26px;
      font-weight: 300;
      margin-top: 37px;
    }

    a {
      font-family: $--SF-UI-Display;
      font-size: 14px;
      font-weight: 500;
      color: #53545c;
      margin-top: 24px;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 940px) {
  .head {
    gap: 30px;
    margin-top: 30px;

    img {
      height: 240px;
      object-fit: cover;
      object-position: center;
    }
    .title {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 36px;
      box-sizing: border-box;

      h2 {
        font-size: 34px;
        line-height: 42px;
      }

      p {
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }

  .productList {
    padding: 0 0px;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr;

    a {
      width: 100%;
      box-sizing: border-box;
      img {
        max-width: 100%;
      }
    }
  }
}
