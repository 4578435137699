@import '../../../assets/style/variables.module.scss';

.flashPhoto {
  height: 130vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #0b0c0f;

  .flash {
    position: fixed;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    background: white;
    z-index: 20;
    opacity: 0;
  }

  h3 {
    position: absolute;
    top: 130px;
    z-index: 2;
    color: #9ba4b5;
    font-size: 90px;
    font-family: 'Bodoni Moda', serif;
    font-weight: 300;
    text-align: center;
    line-height: 60px;
    transition: all 0.5s ease;
  }

  .photoWrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    img {
      border: 8px solid white;
      border-bottom: 60px solid white;
      box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: 0.3);
      border-radius: 3px;
    }

    .photo1 {
      position: absolute;
      z-index: 1;
      height: 657px;
      opacity: 0;
      transition: all 0.5s ease;
    }

    .photo2 {
      position: absolute;
      z-index: 2;
      height: 595px;
      opacity: 0;
      transition: all 0.5s ease;
    }
    .photo3 {
      position: absolute;
      z-index: 3;
      height: 491px;
      opacity: 0;
      transition: all 0.5s ease;
    }
    .photo4 {
      position: absolute;
      z-index: 4;
      height: 435px;
      opacity: 0;
      transition: all 0.5s ease;
    }
    .photo5 {
      position: absolute;
      z-index: 5;
      height: 359px;
      opacity: 0;
      transition: all 0.5s ease;
    }
  }
}

@media only screen and (max-width: 940px) {
  .flashPhoto {
    height: 100vh;
    h3 {
      font-size: 70px;
      line-height: 50px;
    }
  }
}
