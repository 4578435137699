@import '../../assets/style/variables.module.scss';

.home {
  overflow-x: hidden;
  background: white;
}

.signatureWrapper {
  width: 100%;
  max-width: $--max-width-desktop;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 100px;
}

.buy {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 100;
  background: #65eeeb;
  padding: 14px 45px;
  color: #012e2d;
  font-family: 'SF UI Display', sans-serif;
  border-radius: 100px;
  font-size: 14px;
}

.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.productWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  padding: 0 70px;
  box-sizing: border-box;
  margin-bottom: 120px;

  .title {
    color: #53545c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 120px;
    font-family: 'Bodoni Moda', serif;

    h2 {
      font-size: 44px;
      line-height: 50px;
    }
    p {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .productList {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.halfTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 300px;
  margin-top: 100px;
  h4 {
    color: #9ba4b5;
    font-size: 56px;
    font-family: 'Bodoni Moda', serif;
    text-align: center;
    font-weight: 200;
  }
}

.singleProduct {
  margin-bottom: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.triple {
  width: 100%;
  gap: 30px;
  margin: auto;
  padding: 0 70px;
  box-sizing: border-box;
  margin-bottom: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.tripleWrapper {
  display: flex;
  gap: 30px;
  width: 100%;
  align-items: center;
  margin-bottom: 120px;

  img {
    width: 90%;

    &:first-child {
      width: 90%;
    }
  }
}

.feedWrapper {
  width: 100%;
  padding: 0 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  box-sizing: border-box;
  margin-top: 75px;

  .feed {
    width: 100%;
    color: #53545c;
    display: flex;
    flex-direction: column;

    h4 {
      font-family: $--SF-UI-Display;
      font-size: 26px;
      font-weight: 300;
      margin-top: 37px;
    }

    a {
      font-family: $--SF-UI-Display;
      font-size: 14px;
      font-weight: 500;
      color: #53545c;
      margin-top: 24px;
    }

    img {
      width: 100%;
    }
  }
}

.greyArea {
  background-color: #f7f7f7;
  padding-bottom: 75px;
  padding-top: 85px;
}

.quote {
  padding: 0 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 200px;

  svg {
    position: absolute;
    right: 400px;
  }

  img {
    height: 82px;
    margin-top: 45px;
  }

  p {
    font-size: 32px;
    font-family: 'Bodoni Moda', serif;
    color: #53545c;
    line-height: 52px;
  }
}

@media only screen and (max-width: 940px) {
  .buy {
    right: 20px;
    bottom: unset;
    bottom: 60px;
  }

  .triple {
    flex-direction: column;
    padding: 0;
    width: 90%;
    margin-bottom: 30px;
  }

  .halfTitle {
    h4 {
      font-size: 35px;
    }
  }

  .tripleWrapper {
    margin-top: 30px;
    flex-direction: column;
    margin-bottom: 50px;
    gap: 24px;

    .mobileImageWrapper {
      display: flex;
      gap: 24px;
    }
  }
}
