@import '../../assets/style/variables.module.scss';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 25px;

  &::before {
    content: '';
    width: 615px;
    height: 10px;
    background: #f5e5e1;
    position: absolute;
    top: 1100px;
    left: 0;
  }
}

.head {
  position: relative;
  h3 {
    position: absolute;
    bottom: 85px;
    left: 55px;
    color: white;
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }
}

.info {
  display: flex;
  width: 100%;
  max-width: $--max-width-desktop;
  gap: 150px;
  margin-top: 85px;

  position: relative;

  .left {
    display: flex;
    align-items: flex-start;
    gap: 50px;
    position: relative;

    h5 {
      font-size: 24px;
      font-weight: 300;
      line-height: 35px;
      color: #53545d;
      min-width: 200px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;

    p {
      font-size: 18px;
      color: #53545d;
      line-height: 26px;
    }

    p:first-child {
      font-size: 24px;
      font-weight: 300;
      line-height: 35px;
      color: #53545d;
      margin-bottom: 100px;
    }
  }
}

.imageWrapper {
  margin-top: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $--max-width-desktop;
}

.bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
  max-width: $--max-width-desktop;
  margin-bottom: 100px;

  img {
    width: 220px;
    margin-bottom: 50px;
  }

  h4 {
    font-size: 28px;
    font-weight: 300;
    line-height: 35px;
    color: #53545d;
    margin-bottom: 50px;
  }

  h3 {
    font-size: 28px;
    font-weight: 300;
    line-height: 35px;
    color: #53545d;
    margin-bottom: 50px;
    text-decoration: underline;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 35px;
    color: #53545d;
    text-align: center;
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    &::before {
      top: 945px;
      height: 6px;
      width: 220px;
    }

    .head {
      height: 452px;
      width: 95%;

      h3 {
        bottom: 25px;
        left: 25px;
        font-size: 20px;
        line-height: 35px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .info {
      flex-direction: column;
      width: 90%;

      .left {
        flex-direction: column;
        position: relative;
        width: 100%;

        img {
          position: absolute;
          right: 0;
          top: -50px;
        }
      }

      .right {
        p {
          font-size: 16px;
        }

        p:first-child {
          font-size: 24px;
        }
      }
    }

    .imageWrapper {
      flex-direction: column;
      gap: 20px;
      width: 95%;

      img {
        width: 100%;
      }
    }

    .bottom {
      width: 90%;

      h4 {
        text-align: center;
      }
    }
  }
}
